import React, { lazy } from 'react';

import {
  BarsOutlined,
  ClusterOutlined,
  EyeInvisibleOutlined,
  GoldenFilled,
  MoneyCollectOutlined,
  PicCenterOutlined,
  ShoppingCartOutlined,
  SketchOutlined,
  SmileOutlined,
  TeamOutlined,
  UserOutlined,
  MessageFilled,
  ToTopOutlined,
  RedEnvelopeOutlined,
  DisconnectOutlined,
  LayoutFilled,
} from '@ant-design/icons';

const options = [
  {
    key: Math.random(),
    path: '/',
    component: lazy(() => import('../orders')),
    exact: true,
    icon: <ClusterOutlined />,
    name: 'orders',
  },
  {
    key: Math.random(),
    path: '/clientOrders',
    component: lazy(() => import('../clientOrders')),
    exact: true,
    icon: <ShoppingCartOutlined />,
    name: 'client_orders',
  },
  {
    key: Math.random(),
    path: '/offlineOrder',
    component: lazy(() => import('../orderResponsive')),
    exact: true,
    icon: <MoneyCollectOutlined />,
    name: 'offline_orders',
  },
  {
    key: Math.random(),
    path: '/categories',
    component: lazy(() => import('../categories')),
    exact: true,
    icon: <BarsOutlined />,
    name: 'categories',
  },
  {
    key: Math.random(),
    path: '/products',
    component: lazy(() => import('../products')),
    exact: true,
    icon: <GoldenFilled />,
    name: 'products',
  },
  {
    key: Math.random(),
    path: '/brands',
    component: lazy(() => import('../brands')),
    exact: true,
    icon: <SketchOutlined />,
    name: 'brands',
  },
  {
    key: Math.random(),
    path: '/clients',
    component: lazy(() => import('../clients')),
    exact: true,
    icon: <TeamOutlined />,
    name: 'clients',
  },
  {
    key: Math.random(),
    path: '/chats',
    component: lazy(() => import('../chats')),
    exact: true,
    icon: <MessageFilled />,
    name: 'chats',
  },
  // {
  //   key: Math.random(),
  //   path: '/analytics',
  //   component: lazy(() => import('../analytics')),
  //   exact: true,
  //   icon: <LineChartOutlined />,
  //   name: 'analytics',
  // },
  // {
  //   key: Math.random(),
  //   path: '/logger',
  //   component: lazy(() => import('../logger')),
  //   exact: true,
  //   icon: <HistoryOutlined />,
  //   name: 'logger',
  // },
  {
    key: Math.random(),
    path: '/usersAccounts',
    component: lazy(() => import('../usersAccounts')),
    exact: true,
    icon: <UserOutlined />,
    name: 'users_account',
  },
  {
    key: Math.random(),
    path: '/banners',
    component: lazy(() => import('../banners')),
    exact: true,
    icon: <PicCenterOutlined />,
    name: 'banners',
  },
  // {
  //   key: Math.random(),
  //   path: '/userDeliveryPrice',
  //   component: lazy(() => import('../userDeliveryPrice')),
  //   exact: true,
  //   icon: <DollarOutlined />,
  //   name: 'delivery_prices',
  // },
  // {
  //   key: Math.random(),
  //   path: '/notification',
  //   component: lazy(() => import('../notification')),
  //   exact: true,
  //   icon: <NotificationOutlined />,
  //   name: 'notifications',
  // },
  // {
  // 	key: Math.random(),
  // 	path: '/vouchers',
  // 	component: lazy(() => import('../vouchers')),
  // 	exact: true,
  // 	icon: <PercentageOutlined />,
  // 	name: 'vouchers',
  // },
  // {
  //   key: Math.random(),
  //   path: '/segments',
  //   component: lazy(() => import('../segments')),
  //   exact: true,
  //   icon: <AppstoreOutlined />,
  //   name: 'segments',
  // },
  // {
  // 	key: Math.random(),
  // 	path: '/clientOrders',
  // 	component: lazy(() => import('../clientOrders')),
  // 	exact: true,
  // 	icon: <SyncOutlined />,
  // 	name: 'Client Orders',
  // },
  // {
  //   key: Math.random(),
  //   path: '/skuTag',
  //   component: lazy(() => import('../skuTag')),
  //   exact: true,
  //   icon: <ForkOutlined />,
  //   name: 'tags',
  // },
  // {
  //   key: Math.random(),
  //   path: '/marketPlaceLayout',
  //   component: lazy(() => import('../marketPlaceLayout')),
  //   exact: true,
  //   icon: <LayoutOutlined />,
  //   name: 'layout',
  // },
  // {
  //   key: Math.random(),
  //   path: '/apps',
  //   component: lazy(() => import('../apps')),
  //   exact: true,
  //   icon: <AppstoreAddOutlined />,
  //   name: 'apps',
  // },
  {
    key: Math.random(),
    path: '/customers',
    component: lazy(() => import('../customers')),
    exact: true,
    icon: <SmileOutlined />,
    name: 'customers',
  },
  // {
  //   key: Math.random(),
  //   path: '/clientCalendar',
  //   component: lazy(() => import('../clientCalendar')),
  //   exact: true,
  //   icon: <WalletOutlined />,
  //   name: 'client_calendar',
  // },
  // {
  //   key: Math.random(),
  //   path: '/deliveryCaps',
  //   component: lazy(() => import('../deliveryCaps')),
  //   exact: true,
  //   icon: <RocketOutlined />,
  //   name: 'delivery_caps',
  // },
  // {
  //   key: Math.random(),
  //   path: '/customerCredit',
  //   component: lazy(() => import('../customerCredit')),
  //   exact: true,
  //   icon: <PoundOutlined />,
  //   name: 'customer_credit',
  // },
  {
    key: Math.random(),
    path: '/minimumOrder',
    component: lazy(() => import('../minimumOrder')),
    exact: true,
    icon: <ToTopOutlined />,
    name: 'minimum_order',
  },
  // {
  //   key: Math.random(),
  //   path: '/purchaseOrders',
  //   component: lazy(() => import('../purchaseOrders')),
  //   exact: true,
  //   icon: <BookOutlined />,
  //   name: 'purchase_order',
  // },
  // {
  //   key: Math.random(),
  //   path: '/clientPayments',
  //   component: lazy(() => import('../clientPayments')),
  //   exact: true,
  //   icon: <DotChartOutlined />,
  //   name: 'client_payments',
  // },
  // {
  //   key: Math.random(),
  //   path: '/singlePurchaseOrder',
  //   component: lazy(() => import('../purchaseOrders/QrCode')),
  //   exact: true,
  //   name: 'dynamic page PO',
  //   icon: <BookOutlined />,
  //   hidden: true,
  // },
  // {
  //   key: Math.random(),
  //   path: '/verticals',
  //   component: lazy(() => import('../verticals')),
  //   exact: true,
  //   name: 'verticals',
  //   icon: <VerticalAlignMiddleOutlined />,
  // },
  // {
  //   key: Math.random(),
  //   path: '/variantGroups',
  //   component: lazy(() => import('../variantGroups')),
  //   exact: true,
  //   name: 'variant_groups',
  //   icon: <PlusCircleOutlined />,
  // },
  // {
  //   key: Math.random(),
  //   path: '/CashInfo',
  //   component: lazy(() => import('../cashInfo')),
  //   exact: true,
  //   name: 'cash_info',
  //   icon: <RedEnvelopeOutlined />,
  // },
  {
    key: Math.random(),
    path: '/OrderChangeLogger',
    component: lazy(() => import('../orderChangeLogger')),
    exact: true,
    name: 'order_change_logger',
    icon: <RedEnvelopeOutlined />,
  },
  {
    key: Math.random(),
    path: '/ClientDiscountLogger',
    component: lazy(() => import('../clientDiscountLogger')),
    exact: true,
    name: 'client_discount_logger',
    icon: <RedEnvelopeOutlined />,
  },
  {
    key: Math.random(),
    path: '/permissions',
    component: lazy(() => import('../permissions')),
    exact: true,
    icon: <EyeInvisibleOutlined />,
    name: 'permissions',
  },
  {
    key: Math.random(),
    path: '/skuDiscounts',
    component: lazy(() => import('../skuDiscounts')),
    exact: true,
    icon: <DisconnectOutlined />,
    name: 'sku_discounts',
  },
  {
    key: Math.random(),
    path: '/layouts',
    component: lazy(() => import('../layouts')),
    exact: true,
    icon: <LayoutFilled />,
    name: 'layouts',
  },
  {
    key: Math.random(),
    path: '/productLinking',
    component: lazy(() => import('../productLinking')),
    exact: true,
    icon: <GoldenFilled />,
    name: 'product_linking',
  },
  // any new route should be added before this route
  // cause it's a catch all route
  {
    key: Math.random(),
    path: '*',
    component: lazy(() => import('../NotFound/NotFound')),
    exact: true,
    icon: <EyeInvisibleOutlined />,
    name: 'Not found',
    hidden: true,
  },

];
export default options;
